import * as React from 'react';
import { AppBar } from '@material-ui/core';
import { Link } from 'gatsby';
import styled from 'styled-components';
import muiTheme from '../config/theme';

const StyledFooter = styled.footer`
  margin-bottom: 4rem;
`;

const TitleLink = styled(Link)`
  color: ${muiTheme.palette.primary.main};
  text-decoration: none;
  font-weight: 500;

  &:hover {
    color: ${muiTheme.palette.primary.dark};
  }
`;

const StyledAppBar = styled(AppBar)`
  &&& {
    background-color: #faf9fe;
  }
`;

interface Props {
  siteTitle?: string;
}

const Footer: React.FC<Props> = ({ siteTitle }: Props) => <StyledFooter />;

export default Footer;
