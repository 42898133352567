import * as React from 'react';
import { Link } from 'gatsby';
import { Typography, Grid } from '@material-ui/core';
import styled from 'styled-components';
import muiTheme from '../config/theme';

const NavLink = styled(Link)`
  color: ${muiTheme.palette.grey[800]};
  text-decoration: none;
  text-transform: lowercase;
  display: block;

  &.active {
    border-bottom: 1px solid ${muiTheme.palette.primary.main};
  }

  &:hover {
    color: ${muiTheme.palette.primary.main};
  }
`;

export default class Nav extends React.Component {
  navPages = [
    // { to: '/portfolio', name: 'Projects' },
    // { to: '/blogPosts', name: 'Blog' },
    // { to: '/#about', name: 'About' },
    // { to: '/#contact', name: 'Contact' },
  ];

  render() {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-end"
        wrap="nowrap"
      >
        {this.navPages?.length > 0
          ? this.navPages.map(link => {
              return (
                <Grid item key={link.name}>
                  <NavLink to={link.to} activeClassName="active">
                    <Typography variant="button">{link.name}</Typography>
                  </NavLink>
                </Grid>
              );
            })
          : null}
      </Grid>
    );
  }
}
