import * as React from 'react';
import { Grid, Hidden, AppBar, Toolbar, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import styled from 'styled-components';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import Nav from './Nav';
import muiTheme from '../config/theme';

const TitleLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    color: ${muiTheme.palette.primary.dark};
  }
`;

const StyledAppBar = styled(AppBar)`
  transition: all 0.5s ease-out;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: ${(props: { scrolled: boolean }) =>
    props.scrolled ? 'blur(5px)' : 'none'};

  background-color: ${(props: { scrolled: boolean }) =>
    props.scrolled ? 'rgba(255,255,255,.5)' : 'rgba(255,255,255,0)'};
  &&& {
    transition: all 0.5s ease-out;
    background-color: rgba(255, 255, 255, 0);
    background-color: ${(props: { scrolled: boolean }) =>
      props.scrolled ? 'rgba(255,255,255,.5)' : 'rgba(255,255,255,0)'};
  }
`;

interface Props {
  siteTitle?: string;
}

const Header: React.FC<Props> = ({ siteTitle }: Props) => {
  const options = {
    throttle: 100,
  };
  const position =
    typeof window !== 'undefined' ? useWindowScrollPosition(options) : { y: 0 };

  return (
    <StyledAppBar
      position="absolute"
      //  position="fixed"
      //   scrolled={position.y > 0}
      elevation={0}
    >
      <Toolbar>
        <Grid container justify="center" alignItems="center" spacing={4}>
          <Grid
            item
            style={{
              width: '1200px',
              maxWidth: '100%',
            }}
          >
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={4}
            >
              <Grid item>
                <Grid container justify="center" alignItems="center">
                  <Grid item>
                    <Hidden xsDown>
                      <Typography variant="h5" component="h1">
                        <TitleLink to="/">{siteTitle}</TitleLink>
                        <span
                          style={{
                            color: muiTheme.palette.primary.main,
                          }}
                        >
                          .
                        </span>
                      </Typography>
                    </Hidden>
                    <Hidden smUp>
                      <Typography variant="h5" component="h1">
                        <TitleLink to="/">
                          {siteTitle?.match(/\b(\w)/g)?.join('')}
                        </TitleLink>
                        <span
                          style={{
                            color: muiTheme.palette.primary.main,
                          }}
                        >
                          .
                        </span>
                      </Typography>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Nav />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
