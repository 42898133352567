import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const GridContainer = styled(Grid)`
  max-width: ${(props: Partial<Props>) =>
    props.variant === 'wide' ? 1200 : props.variant === 'small' ? 688 : 960}px;
  width: 100%;
  height: 100%;
  padding: ${(props: Partial<Props>) =>
    props.noPad !== true ? '0 1.2rem' : '0px'};
`;

interface Props {
  variant?: 'wide' | 'normal' | 'small';
  noPad?: boolean;
  children: ReactNode;
}

const Container: React.FC<Props> = ({ variant, noPad, children }: Props) => {
  return (
    <Grid container justify="center" direction="row" alignContent="center">
      <GridContainer variant={variant || 'normal'} noPad={noPad || false} item>
        {children}
      </GridContainer>
    </Grid>
  );
};

export default Container;
