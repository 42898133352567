import { createMuiTheme } from '@material-ui/core';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: ['"Muli"', 'sans-serif'].join(),
    h1: {
      fontFamily: ['"IBM Plex Sans"', 'sans-serif'].join(),
      fontWeight: 600,
      fontSize: '2.986em',
    },
    h2: {
      fontFamily: ['"IBM Plex Sans"', 'sans-serif'].join(),
      fontWeight: 500,
      fontSize: '2.488em',
    },
    h3: {
      fontFamily: ['"IBM Plex Sans"', 'sans-serif'].join(),
      fontWeight: 400,
      fontSize: '2.074em',
    },
    h4: {
      fontFamily: ['"IBM Plex Sans"', 'sans-serif'].join(),
      fontWeight: 400,
      fontSize: '1.728em',
    },
    h5: {
      fontFamily: ['"IBM Plex Sans"', 'sans-serif'].join(),
      fontWeight: 400,
      fontSize: '1.44em',
    },
    h6: {
      fontFamily: ['"IBM Plex Sans"', 'sans-serif'].join(),
      fontWeight: 400,
      fontSize: '1.2em',
    },
    button: {
      fontFamily: ['"IBM Plex Sans"', 'sans-serif'].join(),
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '18px',
      lineHeight: '1.7em',
    },
    body2: {
      fontSize: '16px',
      lineHeight: '1.7em',
      fontWeight: 300,
    },
    overline: {
      fontFamily: ['"IBM Plex Sans"', 'sans-serif'].join(),
      fontSize: '1em',
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#0048ff',
    },
    secondary: {
      main: '#0091ea',
    },
  },
});

export default muiTheme;
