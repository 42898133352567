import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Helmet } from 'react-helmet-async';
import { MuiThemeProvider } from '@material-ui/core';
import Header from './Header';
import muiTheme from '../config/theme';
import Footer from './Footer';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Muli', sans-serif;
  }

  p {
    font-size: 18px;
    line-height: 1.58;
  }
`;

const MainLayout = styled.main`
  margin: 0 auto;
  width: 100%;
`;

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          keywords
        }
      }
    }
  `);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ParallaxProvider>
        <GlobalStyle />
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
            {
              name: 'keywords',
              content: data.site.siteMetadata.keywords.toString() || 'keywords',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <MainLayout>{children}</MainLayout>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </ParallaxProvider>
    </MuiThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
