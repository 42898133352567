import * as React from 'react';
import {
  BLOCKS,
  INLINES,
  // , MARKS, Document, Block
} from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';
import IframeContainer from './richContentBlocks/IFrameContainer';
import muiTheme from '../config/theme';

const WrapLink = styled.a`
  text-decoration: none;
  color: ${muiTheme.palette.primary.light};

  &:hover {
    text-decoration: underline;
    color: ${muiTheme.palette.primary.main};
  }
`;

type Props = {
  document: {
    nodeType: BLOCKS.DOCUMENT;
    content: any[];
    data: any;
  };
};

function getObjectKeyArray(obj: any): string[] {
  if (typeof obj !== 'object') return [];
  const arr: string[] = [];
  for (const key in obj) {
    arr.push(key);
  }

  return arr;
}

const ContentfulRichText: React.FC<Props> = ({ document }: Props) => {
  const options: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        if (!node.data.target.fields) return;
        const { file, description, title } = node.data.target.fields;
        const locales = getObjectKeyArray(file);
        const width = 630;

        return locales.map(locale => (
          <figure
            key={locale}
            style={{ maxWidth: '100%', margin: '0px', marginBottom: '2rem' }}
          >
            <picture>
              <source
                type="image/webp"
                srcSet={`${file?.[locale].url}?w=295&h=197&q=85&fm=webp 295w,
                        ${file?.[locale].url}?w=590&h=393&q=85&fm=webp 590w,
                        ${file?.[locale].url}?w=1180&h=786&q=85&fm=webp 1180w,
                        ${file?.[locale].url}?w=1770&h=1180&q=85&fm=webp 1770w,
                        ${file?.[locale].url}?w=2048&h=1365&q=85&fm=webp 2048w`}
                sizes={`(max-width: ${width}px) 100vw, ${width}px`}
              />
              <source
                srcSet={`${file?.[locale].url}?w=295&h=197&q=85 295w,
                        ${file?.[locale].url}?w=590&h=393&q=85 590w,
                        ${file?.[locale].url}?w=1180&h=786&q=85 1180w,
                        ${file?.[locale].url}?w=1770&h=1180&q=85 1770w,
                        ${file?.[locale].url}?w=2048&h=1365&q=85 2048w`}
                sizes={`(max-width: ${width}px) 100vw, ${width}px`}
              />
              <img
                sizes={`(max-width: ${width}px) 100vw, ${width}px`}
                style={{
                  width: '100%',
                }}
                srcSet={`${file?.[locale].url}?w=295&h=197&q=85 295w,
                        ${file?.[locale].url}?w=590&h=393&q=85 590w,
                        ${file?.[locale].url}?w=1180&h=786&q=85 1180w,
                        ${file?.[locale].url}?w=1770&h=1180&q=85 1770w,
                        ${file?.[locale].url}?w=2048&h=1365&q=85 2048w`}
                src={`${file?.[locale].url}?w=1180&q=85`}
                alt={description?.[locale] || title?.[locale]}
                data-locale={locale}
              />
            </picture>
            <figcaption
              style={{
                textAlign: 'center',
                fontStyle: 'italic',
                color: muiTheme.palette.text.secondary,
              }}
            >
              {title?.[locale]}
            </figcaption>
          </figure>
        ));
      },
      [INLINES.HYPERLINK]: node => {
        if (node.data.uri.includes('player.vimeo.com/video')) {
          return (
            <IframeContainer>
              <iframe
                title="Unique Title 001"
                src={node.data.uri}
                frameBorder="0"
                allowFullScreen
              />
            </IframeContainer>
          );
        }
        if (node.data.uri.includes('youtube.com/embed')) {
          return (
            <IframeContainer>
              <iframe
                title="Unique Title 002"
                src={node.data.uri}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
              />
            </IframeContainer>
          );
        }

        return (
          <WrapLink href={node.data.uri}>
            {node.content.map(val => val.value)}
          </WrapLink>
        );
      },
    },
  };
  return <div>{documentToReactComponents(document, options)}</div>;
};
export default ContentfulRichText;
